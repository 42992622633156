<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png"
             alt
             @click="goBack" />
        <span class="center">发票管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">发票列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">发票详情</span>
      </div>
    </div>
    <el-card class="box-card">
      <Divider :title="'发票信息'"></Divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="销售方">
          {{
          detailInfo.enterprise.name
          }}
        </el-descriptions-item>
        <el-descriptions-item label="发票图片">
          <el-tooltip class="item"
                      effect="dark"
                      content="点击查看图片"
                      placement="top-start">
            <el-button style="padding:0"
                       @click="showImg(detailInfo.imgUrl, '查看照片')"
                       type="text">查看照片</el-button>
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{detailInfo.createTime}}</el-descriptions-item>

        <el-descriptions-item label="发票备注">
          {{
          detailInfo.remark
          }}
        </el-descriptions-item>
      </el-descriptions>
      <Divider :title="'发放信息'"></Divider>
      <div v-for="item in detailInfo.task"
           :key="item.code"
           style="display: flex; align-items: center;justify-content: space-between;padding:10px 0;margin:10px;">
        <div>发放人数：{{item.makerNumber}}</div>
        <div>结算金额：{{item.total}}</div>
        <div>
          支付状态：
          <span v-if="item.payState==='1'">支付成功</span>
          <span v-if="item.payState==='2'">失败</span>
          <span v-if="item.payState==='3'">部分成功</span>
        </div>
        <div>支付时间：{{item.payTime}}</div>
      </div>
      <Divider :title="'快递信息'"></Divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="快递公司">{{detailInfo.expressName}}</el-descriptions-item>

        <el-descriptions-item label="快递单号">
          {{
          detailInfo.expressCode
          }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-dialog class="showImgDialog"
               width="680px"
               :center="true"
               :title="dialogTitle"
               :visible.sync="showImgVisible"
               :append-to-body="true">
      <div class="imgBox">
        <img :src="dialogImgUrl"
             class="img-resource"
             alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const upperEnterpriseDispatchInfo = api()('upper_enterprise.invoice.info.json');
export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      code: '',
      detailInfo: {},
      activeName: '',
      showImgVisible: false,
      dialogTitle: '',
      dialogImgUrl: '',
      obj: {},
    };
  },
  mounted () {
    this.code = this.$route.query.code;
    this.loadData();
  },
  methods: {
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },

    loadData () {
      const params = {
        code: this.code,
      };
      upperEnterpriseDispatchInfo(params).then((res) => {
        this.detailInfo = res;


        console.log(this.detailInfo);
      });
    },
    goBack () {
      this.$router.go(-1);
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
  },
};
</script>
<style lang="scss" scoped></style>
